$(document).ready(function() {
    $('input[name=target-page]').val($('title').text());
    let device_width = $(window).width();
    if(device_width > 1200) {
        setTimeout(function () {
            menuAnimate();
        }, 500);
    }
    $('.open-home-menu').click(function(e){
        e.preventDefault();
        $('.homepage-menu').prepend('<div class="home-menu-closer"></div>');
        $(this).fadeOut(200);
        $('.home-menu.inside .homepage-menu').css({'right':'15px'});
        menuAnimate();
        $('.home-menu-closer').show();
    });
    $(document).on('click','.home-menu-closer', function(){
        $(this).hide();
        $('.home-menu.inside .homepage-menu').css({'right':'-320px'});
        menuAnimate(true);
        setTimeout(function () {
            $('.open-home-menu').fadeIn(200);
        }, 1200);
        $('.home-menu-closer').hide();
    });
    $(document).mousemove(function( event ) {
        let mouseX = event.pageX;
        let sun_width = $('.the-sun').width() / 2;
        let pos = mouseX * 0.1 + (device_width / 2 - sun_width);
        //let mouseY = event.pageY;
        $('.the-sun').css('left', pos);
        //console.log(pos);
    });
    $('a.modal-button').click(function(e){
        e.preventDefault();
        openPopup();
    });
    $('.popup-bg .closer').click(function(){
        closeAll();
    });
});

function openPopup() {
    $('.popup-bg').show(200);
    $('.popup').fadeIn(200);
}
function closeAll () {
    $('.popup-bg').hide(200);
    $('.popup').fadeOut(200);
    $('.popup-thx').fadeOut(200);
}


let wpcf7Elm = document.querySelector( '.wpcf7' );
wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
    openThanks();
}, false );

function openThanks(){
    $('.popup').fadeOut(200);
    $('.popup-thx').fadeIn(200);
}

$(document).on('click', '.dd-menu', function (e) {
    e.preventDefault();
    //$('.main-menu').slideDown(300);
    $('.main-menu').toggleClass('opened');
    $('.top-data, header').toggleClass('menu-opened');
});
$(document).on('click', '.main-menu .closer', function (e) {
    e.preventDefault();
    $('.main-menu').toggleClass('opened');
    $('.top-data, header').toggleClass('menu-opened');
});

function menuAnimate(reverse) {
    let licount = 0;
    let position_to = reverse ? 310 : 0;
    $('.homepage-menu li').each(function(){
        licount++;
        let li = $(this);
        setTimeout(function (){
            li.css({left:position_to, opacity:1})
        },licount*200);
    });
}
